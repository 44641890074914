// Generated by Framer (edb532b)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, SVG, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Pulse from "../codeFile/Pulse.tsx";
const PulseFonts = getFonts(Pulse);

const cycleOrder = ["cF1ya8VUC"];

const serializationHash = "framer-GLYH5"

const variantClassNames = {cF1ya8VUC: "framer-v-1vpix6r"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({click, height, id, width, ...props}) => { return {...props, WV_gMsgQ0: click ?? props.WV_gMsgQ0} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WV_gMsgQ0, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "cF1ya8VUC", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap27wkt1 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (WV_gMsgQ0) {const res = await WV_gMsgQ0(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1vpix6r", className, classNames)} data-border data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"cF1ya8VUC"} onTap={onTap27wkt1} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(33, 33, 33, 0.1)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 48, borderBottomRightRadius: 48, borderTopLeftRadius: 48, borderTopRightRadius: 48, ...style}}><ComponentViewportProvider ><motion.div className={"framer-j4f98b-container"} layoutDependency={layoutDependency} layoutId={"YpZSuoiia-container"}><Pulse borderWidth={2} color={"var(--token-2930bc44-c91b-4c30-943e-1add685a6fe2, rgb(223, 255, 26))"} delay={0.6} direction={"out"} height={"100%"} id={"YpZSuoiia"} layoutId={"YpZSuoiia"} pulseOpacity={0.5} pulseSize={18} radiusType={"full"} radiusValue={20} style={"solid"} transition={{damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 150, type: "spring"}} width={"100%"}/></motion.div></ComponentViewportProvider><SVG className={"framer-1etz4u4"} data-framer-name={"graphic"} fill={"rgb(13, 13, 13)"} intrinsicHeight={32} intrinsicWidth={32} layoutDependency={layoutDependency} layoutId={"S5TBZok2A"} svg={"<svg width=\"32\" height=\"32\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6 7.537c0-1.901 2.039-3.106 3.705-2.19l15.387 8.464c1.727.949 1.727 3.43 0 4.38L9.707 26.655C8.04 27.57 6 26.365 6 24.464V7.537Z\" fill=\"#0D0D0D\"/></svg>"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-GLYH5.framer-1d11pbv, .framer-GLYH5 .framer-1d11pbv { display: block; }", ".framer-GLYH5.framer-1vpix6r { cursor: pointer; height: 64px; overflow: visible; position: relative; width: 64px; }", ".framer-GLYH5 .framer-j4f98b-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }", ".framer-GLYH5 .framer-1etz4u4 { flex: none; height: 32px; left: calc(50.72463768115944% - 32px / 2); position: absolute; top: calc(50.72463768115944% - 32px / 2); width: 32px; }", ".framer-GLYH5[data-border=\"true\"]::after, .framer-GLYH5 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 64
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WV_gMsgQ0":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerNfjnQpvPE: React.ComponentType<Props> = withCSS(Component, css, "framer-GLYH5") as typeof Component;
export default FramerNfjnQpvPE;

FramerNfjnQpvPE.displayName = "Elements/Play Button";

FramerNfjnQpvPE.defaultProps = {height: 64, width: 64};

addPropertyControls(FramerNfjnQpvPE, {WV_gMsgQ0: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerNfjnQpvPE, [{explicitInter: true, fonts: []}, ...PulseFonts], {supportsExplicitInterCodegen: true})